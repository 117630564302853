<template>
  <div>
    <div class="formgrid grid">
      <div class="field col-12">
        <DataTable
          v-model:selection="selected"
          v-model:filters="filters"
          :value="list_sales_order"
          :globalFilterFields="['sp_no', 'nama_obat']"
          dataKey="id"
          responsiveLayout="scroll"
          class="p-datatable-sm"
        >
          <template #header>
            <div class="flex justify-content-end">
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText
                  v-model="filters['global'].value"
                  placeholder="Filter obat"
                />
              </span>
            </div>
          </template>
          <Column selectionMode="multiple" headerStyle="width: 3em" />
          <Column field="sp_no" header="Sales Order" style="min-width: 15rem" />
          <Column field="nama_obat" header="Obat" style="min-width: 15rem" />
          <Column field="quantity" header="QTY" style="min-width: 3rem" />
          <Column field="satuan" header="Satuan" style="min-width: 5rem" />
          <Column
            field="quantity_tkcl"
            header="QTY terkecil"
            style="min-width: 5rem"
          />
          <Column
            field="satuan_tkcl"
            header="Satuan terkecil"
            style="min-width: 5rem"
          />
          <Column field="harga" header="Harga" style="min-width: 3rem">
            <template #body="{ data }">
              {{ formatCurrency(data.harga) }}
            </template>
          </Column>
          <Column field="diskon" header="Diskon (%)" style="min-width: 3rem" />
          <Column
            field="diskon_rp"
            header="Diskon (Rp)"
            style="flex-grow: 1; min-width: 3rem; justify-content: flex-end"
          >
            <template #body="{ data }">
              {{ formatCurrency(data.diskon_rp) }}
            </template>
          </Column>
          <Column
            field="dpp"
            header="Harga akhir"
            style="flex-grow: 1; min-width: 5rem; justify-content: flex-end"
          >
            <template #body="{ data }">
              {{ formatCurrency(data.dpp) }}
            </template>
          </Column>
          <Column
            field="jumlah"
            header="harga_total"
            style="flex-grow: 1; min-width: 9rem; justify-content: flex-end"
          >
            <template #body="{ data }">
              {{ formatCurrency(data.harga_total) }}
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
    <div class="flex justify-content-end align-items-end">
      <Button
        label="Tutup"
        icon="pi pi-times"
        class="p-button-text mr-2"
        @click="$emit('close')"
      />
      <Button
        :disabled="disabledSave"
        label="Tambahkan"
        icon="pi pi-check"
        @click="add"
      />
    </div>
  </div>
</template>

<script>
import { formatCurrency, formatNumber, formatDate } from '@/helpers'
import { FilterMatchMode, FilterOperator } from 'primevue/api'

export default {
  props: {
    salesOrder: {
      type: Array,
      default() {
        return []
      },
    },
    excludeList: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      selected: null,
      disabledSave: true,
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        sp_no: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      },
    }
  },
  watch: {
    selected(val) {
      if (val && val.length > 0) {
        this.disabledSave = false
      } else {
        this.disabledSave = true
      }
    },
  },
  computed: {
    list_sales_order() {
      let items = []
      this.salesOrder.forEach((so) => {
        so.obat.forEach((el) => {
          el.sp_no = so.sp_no
          el.golongan = so.golongan.nama
          el.sp_no = so.sp_no
          el.sp_at = so.sp_at
          el.ppn = so.ppn
          items.push(el)
        })
      })
      const result = items.slice()
      // Tampilkan data obat hanya yang belum dipilih
      return result.filter((item) => {
        return this.excludeList.indexOf(item.id) === -1
      })
    },
  },
  methods: {
    add() {
      this.$emit('save', this.selected)
    },
    formatCurrency(value, minDigit = 0, maxDigit = 0) {
      return formatCurrency(value, minDigit, maxDigit)
    },
    formatNumber(value) {
      return formatNumber(value)
    },
    formatDate(value) {
      return formatDate(value)
    },
  },
}
</script>
