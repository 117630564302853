<template>
  <Dialog
    header="Riwayat status transaksi"
    v-model:visible="dialogRiwayat"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '50vw' }"
    modal
    closeable
  >
    <DataTable :value="items" class="p-datatable-sm">
      <template #empty>
        <div class="text-center">Tidak ada data.</div>
      </template>
      <template #loading>
        <div class="text-center">Memuat data, harap tunggu...</div>
      </template>
      <Column field="status" header="Status" style="min-width: 2rem">
        <template #body="{ data }">
          <div :class="'status-badge status-' + data.status">
            {{ data.status }}
          </div>
        </template>
      </Column>
      <Column field="created_at" header="Tanggal">
        <template #body="{ data }">
          {{ formatDate(data.created_at) }}
        </template>
      </Column>
      <Column field="created_by" header="User" />
    </DataTable>
  </Dialog>
  <Button
    type="button"
    icon="pi pi-list"
    class="p-button-outlined p-button-secondary p-button-text mr-2"
    v-tooltip.top="'Riwayat status'"
    @click="dialogRiwayat = true"
  />
</template>

<script>
import { formatDate } from '@/helpers'

export default {
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      dialogRiwayat: false,
    }
  },
  methods: {
    formatDate(value) {
      return formatDate(value, true)
    },
  },
}
</script>
