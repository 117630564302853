<template>
  <div>
    <DataTable
      v-model:filters="filters"
      v-model:expandedRows="expandedRows"
      :value="items"
      :loading="loading"
      :rows="10"
      lazy
      row-hover
      paginator
      :totalRecords="total"
      :rowsPerPageOptions="[10, 25, 50]"
      class="p-datatable-sm"
      dataKey="id"
      filterDisplay="row"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      currentPageReportTemplate="Memuat {first} dari {last} data dari total {totalRecords}"
      scrollable
      @page="onPage($event)"
      @sort="onSort($event)"
      @filter="onFilter($event)"
    >
      <template #empty>
        <div class="text-center">Tidak ada data.</div>
      </template>
      <template #loading>
        <div class="text-center">Memuat data, harap tunggu...</div>
      </template>
      <Column style="min-width: 3rem; flex: initial" header="" expander />
      <Column field="sp_no" header="No" style="min-width: 20rem" sortable>
        <template #body="{ data }">
          {{ data.sp_no }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            @keydown.enter="filterCallback()"
          />
        </template>
      </Column>
      <Column
        sortable
        dataType="date"
        filter-field="order_between"
        field="sp_at"
        header="Tanggal"
        style="min-width: 19rem"
      >
        <template #body="{ data }">
          {{ formatDate(data.sp_at) }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <CalendarFilter
            ref="calendarFilter"
            v-model="filterModel.value"
            class="p-column-filter"
            @select="filterCallback()"
          />
        </template>
      </Column>
      <Column
        sortable
        field="pelanggan.nama"
        sort-field="pelanggan"
        filter-field="pelanggan"
        header="Pelanggan"
        style="min-width: 19rem"
      >
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            @keydown.enter="filterCallback()"
          />
        </template>
      </Column>
      <Column
        sortable
        field="salesman.nama"
        sort-field="salesman"
        filter-field="salesman"
        header="Salesman"
        style="min-width: 190px"
      >
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            @keydown.enter="filterCallback()"
          />
        </template>
      </Column>
      <Column
        sortable
        field="golongan.nama"
        sort-field="golongan"
        filter-field="golongan"
        header="Golongan"
        style="min-width: 190px"
      >
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            @keydown.enter="filterCallback()"
          />
        </template>
      </Column>
      <Column sortable field="status" header="Status" style="min-width: 13rem">
        <template #body="{ data }">
          <div :class="'status-badge status-' + data.status">
            {{ data.status }}
          </div>
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <Dropdown
            v-model="filterModel.value"
            :options="['draft', 'sent', 'valid', 'pick']"
            :showClear="true"
            class="p-column-filter"
            @change="filterCallback()"
          />
        </template>
      </Column>
      <Column header="Status At" style="min-width: 10rem">
        <template #body="{ data }">
          <div>
            {{ formatDateTime(data.status_at) }}
          </div>
        </template>
      </Column>
      <Column
        sortable
        field="jumlah"
        header="Total"
        dataType="numeric"
        style="
          min-width: 60px;
          display: flex;
          flex-grow: 1;
          justify-content: flex-end;
        "
      >
        <template #body="{ data }">
          {{ formatCurrency(data.jumlah) }}
        </template>
      </Column>
      <Column
        style="
          display: flex;
          flex-grow: 1;
          justify-content: flex-end;
          min-width: 200px;
        "
      >
        <template #body="{ data }">
          <Button
            type="button"
            icon="pi pi-print"
            class="p-button-outlined p-button-secondary p-button-text mr-2"
            v-tooltip.top="'Cetak sales order'"
            @click="$emit('print', data)"
          />
          <riwayat-status :items="data.status_transition" />
          <Button
            type="button"
            icon="pi pi-pencil"
            class="p-button-outlined p-button-secondary p-button-text"
            @click="$emit('edit', data)"
            :disabled="
              auth.user.roles.some((a) => ['pembelian'].includes(a))
            "
          />
          <Button
            :disabled="data.status !== 'draft'"
            type="button"
            icon="pi pi-trash"
            class="p-button-outlined p-button-danger p-button-text"
            @click="$emit('delete', data)"
          />
        </template>
      </Column>
      <template #expansion="slotProps">
        <div class="card">
          <DataTable
            v-model:expandedRows="expandedRows"
            :value="slotProps.data.obat"
            :scrollable="true"
            class="p-datatable-sm"
            showGridlines
            scrollHeight="400px"
          >
            <Column :expander="true" style="min-width: 3rem" />
            <Column field="nama_obat" header="Obat" style="min-width: 15rem" />
            <Column field="quantity" header="QTY" style="min-width: 3rem" />
            <Column field="satuan" header="Satuan" style="min-width: 5rem" />
            <Column
              field="quantity_tkcl"
              header="QTY terkecil"
              style="min-width: 5rem"
            />
            <Column
              field="satuan_tkcl"
              header="Satuan terkecil"
              style="min-width: 5rem"
            />
            <Column field="harga" header="Harga" style="min-width: 3rem">
              <template #body="{ data }">
                {{ formatCurrency(data.harga) }}
              </template>
            </Column>
            <Column
              field="diskon"
              header="Diskon (%)"
              style="min-width: 3rem"
            />
            <!--
            <Column
              field="harga_diskon_display"
              header="Harga diskon"
              style="flex-grow: 1; min-width: 3rem; justify-content: flex-end"
            >
              <template #body="{ data }">
                {{ formatCurrency(data.harga_diskon_display) }}
              </template>
            </Column>
            -->
            <Column
              field="dpp"
              header="DPP"
              style="flex-grow: 1; min-width: 5rem; justify-content: flex-end"
            >
              <template #body="{ data }">
                {{ formatCurrency(data.dpp) }}
              </template>
            </Column>
            <Column
              field="harga_total"
              header="Jumlah"
              style="flex-grow: 1; min-width: 9rem; justify-content: flex-end"
            >
              <template #body="{ data }">
                {{ formatCurrency(data.harga_total) }}
              </template>
            </Column>
            <Column
              field="keterangan"
              header="Keterangan"
              style="flex-grow: 1; min-width: 9rem; justify-content: flex-start"
            >
              <template #body="{ data }">
                {{ data.keterangan }}
              </template>
            </Column>
            <template #expansion="detail">
              <grid-batch :items="detail.data.batch" :show-button="false" />
            </template>
          </DataTable>
        </div>
      </template>
    </DataTable>
  </div>
</template>

<script>
import { FilterMatchMode } from 'primevue/api'
import { formatCurrency, formatDate } from '@/helpers'
import CalendarFilter from '@/components/CalendarFilter'
import GridBatch from '@/components/batch/GridBatch'
import RiwayatStatus from '@/components/RiwayatStatus'
import { useAuthStore } from '@/store/auth'

export default {
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: 0,
    },
    gridOptions: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  components: {
    CalendarFilter,
    GridBatch,
    RiwayatStatus,
  },
  data() {
    return {
      expandedRows: [],
      options: this.gridOptions,
      filters: {
        sp_no: { value: null, matchMode: FilterMatchMode.CONTAINS },
        order_between: { value: null, matchMode: FilterMatchMode.CONTAINS },
        pelanggan: { value: null, matchMode: FilterMatchMode.CONTAINS },
        salesman: { value: null, matchMode: FilterMatchMode.CONTAINS },
        golongan: { value: null, matchMode: FilterMatchMode.CONTAINS },
        status: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      auth: {},
    }
  },
  watch: {
    'options.filters'(value) {
      if (!value.order_between.value) {
        this.$refs.calendarFilter.clearValueOnly()
      }
    },
  },
  created() {
    this.auth = useAuthStore()
  },
  methods: {
    onPage(e) {
      e.page = e.page + 1
      e.sortField = this.options.sortField
      e.sortOrder = this.options.sortOrder
      this.options = e
      this.options.filters = this.filters
      this.loadData()
    },
    onSort(e) {
      this.options = e
      this.loadData()
    },
    onFilter() {
      this.options.page = 1
      this.options.filters = this.filters
      this.loadData()
    },
    loadData() {
      this.$emit('request', this.options)
    },
    formatCurrency(value, minDigit = 0, maxDigit = 0) {
      return formatCurrency(value, minDigit, maxDigit)
    },
    formatDate(value) {
      return formatDate(value)
    },
    formatDateTime(value) {
      return formatDate(value, true)
    },
  },
}
</script>
