import http from '../clients/Axios'

export default class PiutangService {
  async paginate(pageNumber, pageSize = 10, sortField, sortOrder, filters) {
    pageNumber = pageNumber === undefined ? 1 : pageNumber
    const sort = sortOrder === 1 ? '-' + sortField : sortField

    let filters_param = ''

    if (filters) {
      const keys = Object.keys(filters)
      keys.forEach((el) => {
        let val = filters[el].value
        if (el === 'giro_status') {
          if (val === true) {
            val = 'cair'
          } else {
            val = ''
          }
        }
        if (filters[el].value) {
          filters_param = filters_param + '&filter[' + el + ']=' + val
        }
      })
    }

    const res = await http.get(
      `/sales/payment/paginate?page[number]=${pageNumber}&page[size]=${pageSize}&sort=${sort}${filters_param}`
    )

    return res.data
  }

  async get(param) {
    param = param ? param : ''
    const res = await http.get(`/sales/payment${param}`)
    return res
  }

  async add(form) {
    const res = await http.post('/sales/payment', form)
    return res
  }

  async update(form) {
    const res = await http.put(`/sales/payment/${form.id}`, form)
    return res
  }

  async delete(id) {
    const res = await http.delete(`/sales/payment/${id}`)
    return res
  }

  async export(sortField, sortOrder, filters) {
    const sort = sortOrder === 1 ? '-' + sortField : sortField

    let filters_param = ''

    if (filters) {
      const keys = Object.keys(filters)

      keys.forEach((el) => {
        if (filters[el].value) {
          filters_param =
            filters_param + '&filter[' + el + ']=' + filters[el].value
        }
      })
    }

    const unixTime = Math.floor(Date.now() / 1000)
    return http({
      url: `/sales/payment/export?sort=${sort}${filters_param}&t=${unixTime}`,
      method: 'GET',
      responseType: 'blob',
    })
  }

  async printFaktur(faktur_id) {
    return http({
      url: `/sales/payment/${faktur_id}/print`,
      method: 'GET',
      responseType: 'blob',
    })
  }
}
