<template>
  <div>
    <form @submit.prevent="handleSubmit">
      <div class="formgrid grid">
        <div class="field col-12">
          <label>Obat</label>
          <InputText
            v-model="form.nama_obat"
            type="text"
            class="w-full"
            disabled
          />
        </div>
        <div class="field col-12 md:col-3">
          <label>Quantity</label>
          <InputNumber
            v-model="form.quantity_tkcl"
            :minFractionDigits="0"
            type="text"
            class="w-full"
            input-class="w-full"
            autofocus
            disabled
          />
        </div>
        <div class="field col-12 md:col-4">
          <label>Satuan</label>
          <InputText
            v-model="form.satuan"
            type="text"
            class="w-full"
            disabled
          />
        </div>
        <div class="field col-12 md:col-5">
          <label>Harga</label>
          <div class="w-full">
            <InputNumber
              v-model="form.harga"
              :minFractionDigits="2"
              mode="currency"
              currency="IDR"
              locale="id-ID"
              class="w-full"
              input-class="w-full"
              :disabled="disableHarga"
              @input="updateHarga"
              @keyup.enter="handleSubmit()"
            />
          </div>
        </div>
        <div class="field col-12 md:col-3">
          <label>Diskon (%)</label>
          <InputNumber
            v-model="form.diskon"
            :minFractionDigits="2"
            :min="0"
            :max="100"
            :disabled="disableDiskon"
            locale="id-ID"
            type="text"
            class="w-full"
            input-class="w-full"
            @input="updateDiskon"
            @keyup.enter="handleSubmit()"
          />
        </div>
        <div class="field col-12 md:col-3">
          <label>Diskon (Rp)</label>
          <InputNumber
            v-model="form.diskon_rp"
            :minFractionDigits="0"
            :disabled="disableDiskonHarga"
            class="w-full"
            input-class="w-full"
            @input="updateDiskonHarga"
            @keyup.enter="handleSubmit()"
          />
        </div>
        <div class="field col-12 md:col-3">
          <label>Harga Diskon</label>
          <InputNumber
            v-model="form.harga_diskon"
            :minFractionDigits="0"
            mode="currency"
            currency="IDR"
            locale="id-ID"
            class="w-full"
            input-class="w-full"
            disabled
          />
        </div>
        <div class="field col-12 md:col-3">
          <label>Jumlah</label>
          <InputNumber
            v-model="form.jumlah"
            :minFractionDigits="0"
            mode="currency"
            currency="IDR"
            locale="id-ID"
            class="w-full"
            input-class="w-full"
            disabled
          />
        </div>
      </div>
      <div class="flex justify-content-end align-items-end">
        <Button
          :loading="loading"
          label="Simpan"
          icon="pi pi-check"
          type="submit"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { formatCurrency, formatDate } from '@/helpers'
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      disableHarga: false,
      disableDiskon: false,
      disableDiskonHarga: false,
    }
  },
  mounted() {
    if (this.form.diskon > 0) {
      this.disableDiskonHarga = true
    } else if (this.form.diskon_rp > 0) {
      this.disableDiskon = true
    }
    if (this.form.status_bonus) {
      this.disableDiskonHarga = true
      this.disableDiskon = true
      this.disableHarga = true
    }
  },
  computed: {
    form() {
      return this.item
    },
  },
  methods: {
    handleSubmit() {
      this.$emit('save', this.form)
    },
    updateHarga(e) {
      this.hitungHarga(
        e.value,
        this.form.diskon,
        this.form.diskon_rp,
        this.form.quantity_tkcl
      )
    },
    updateDiskon(e) {
      this.hitungHarga(
        this.form.harga,
        e.value,
        this.form.diskon_rp,
        this.form.quantity_tkcl
      )
      this.disableDiskonHarga = e.value > 0
    },
    updateDiskonHarga(e) {
      this.hitungHarga(
        this.form.harga,
        this.form.diskon,
        e.value,
        this.form.quantity_tkcl
      )
      this.disableDiskon = e.value > 0
    },
    hitungHarga(harga, diskon, diskon_rp, quantity_tkcl) {
      if (diskon > 0) {
        diskon = diskon > 100 ? 100 : diskon
        this.form.harga_diskon = harga - harga * (diskon / 100)
      } else {
        this.form.harga_diskon = harga - diskon_rp
      }
      this.form.dpp = quantity_tkcl * this.form.harga_diskon
    },
    formatCurrency(value, minDigit = 0, maxDigit = 0) {
      return formatCurrency(value, minDigit, maxDigit)
    },
    formatDate(value) {
      return formatDate(value)
    },
  },
}
</script>
