import http from '../clients/Axios'

export default class SalesOrderService {
  async paginate(pageNumber, pageSize = 10, sortField, sortOrder, filters) {
    pageNumber = pageNumber === undefined ? 1 : pageNumber
    let sort = sortOrder === 1 ? '-' + sortField : sortField

    sort += ',-id'

    let filters_param = ''

    if (filters) {
      const keys = Object.keys(filters)

      keys.forEach((el) => {
        if (filters[el].value) {
          filters_param =
            filters_param + '&filter[' + el + ']=' + filters[el].value
        }
      })
    }

    const res = await http.get(
      `/sales/order/paginate?page[number]=${pageNumber}&page[size]=${pageSize}&sort=${sort}${filters_param}&timestamp=${new Date().getTime()}`
    )

    return res.data
  }

  async get(param) {
    param = param ? param : ''
    const res = await http.get(`/sales/order${param}`)
    return res
  }

  async add(form) {
    const res = await http.post('/sales/order', form)
    return res
  }

  async update(form) {
    const res = await http.put(`/sales/order/${form.id}`, form)
    return res
  }

  async delete(id) {
    const res = await http.delete(`/sales/order/${id}`)
    return res
  }

  async deleteObat(order_id, obat_id) {
    const res = await http.delete(`/sales/order/${order_id}/${obat_id}`)
    return res
  }

  async print(id) {
    return http({
      url: `/sales/order/${id}/print`,
      method: 'GET',
      responseType: 'blob',
    })
  }
}
