<template>
  <div>
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
      <div class="formgrid grid">
        <div class="field col-6">
          <label>Tanggal</label>
          <Calendar
            v-model="form.kb_at"
            class="w-full"
            dateFormat="dd/mm/yy"
            disabled
          />
        </div>
        <div class="field col-12 md:col-6">
          <label>No Kontra</label>
          <InputText v-model="form.kb_no" type="text" class="w-full" disabled />
        </div>
        <div class="field col-12 md:col-6">
          <label>Sisa</label>
          <InputNumber
            v-model="form.jumlah"
            :minFractionDigits="0"
            mode="currency"
            currency="IDR"
            locale="id-ID"
            class="w-full"
            input-class="w-full"
            disabled
          />
        </div>
        <!--<div class="field col-12 md:col-4">
          <label>Diskon</label>
          <InputNumber
            v-model="form.diskon"
            :minFractionDigits="0"
            mode="currency"
            currency="IDR"
            locale="id-ID"
            class="w-full"
            input-class="w-full"
            autofocus
          />
        </div>-->
        <!-- @input="updateTotal" -->
        <div class="field col-12 md:col-6">
          <label>Jumlah</label>
          <InputNumber
            v-model="form.jumlah_dibayar"
            :minFractionDigits="0"
            mode="currency"
            currency="IDR"
            locale="id-ID"
            class="w-full"
            input-class="w-full"
            disabled
          />
        </div>
        <div class="field col-12">
          <label>Keterangan</label>
          <InputText
            v-model="form.keterangan"
            class="w-full"
            input-class="w-full"
          />
        </div>
      </div>
      <div class="flex justify-content-end align-items-end">
        <Button
          :loading="loading"
          label="Simpan"
          icon="pi pi-check"
          type="submit"
        />
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      submitted: false,
      form: this.item,
    }
  },
  methods: {
    handleSubmit(isFormValid) {
      this.submitted = true

      if (!isFormValid) {
        return
      }

      this.$emit('save', this.form)
    },
  },
}
</script>
