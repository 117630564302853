<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span v-if="id > 0" class="pr-2 mr-2 font-bold text-2xl"
        >Pembayaran Piutang - Edit</span
      >
      <span v-else class="pr-2 mr-2 font-bold text-2xl"
        >Pembayaran Piutang - Tambah</span
      >
      <div>
        <i class="pi pi-home mr-2"></i>
        <span v-if="id > 0" class="font-small"
          >/ Penjualan / Pembayaran piutang / Edit</span
        >
        <span v-else class="font-small"
          >/ Penjualan / Pembayaran piutang / Tambah</span
        >
      </div>
    </div>
    <form-pembayaran :id="id" />
  </div>
</template>

<script>
import FormPembayaran from '@/components/penjualan/FormPembayaran'

export default {
  components: {
    FormPembayaran,
  },
  data() {
    return {
      id: parseInt(this.$route.params.id),
    }
  },
}
</script>
