<template>
  <div>
    <div class="formgrid grid">
      <div class="field col-12">
        <DataTable
          v-model:selection="selected"
          v-model:filters="filters"
          :value="list_invoice"
          :globalFilterFields="['si_no', 'si_at', 'jumlah']"
          dataKey="id"
          responsiveLayout="scroll"
        >
          <template #header>
            <div class="flex justify-content-end">
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText
                  v-model="filters['global'].value"
                  placeholder="Filter invoice"
                />
              </span>
            </div>
          </template>
          <Column selectionMode="multiple" headerStyle="width: 3em" />
          <Column field="si_no" header="Invoice" style="min-width: 15rem" />
          <Column field="si_at" header="Tanggal" style="min-width: 15rem">
            <template #body="{ data }">
              {{ formatDate(data.si_at) }}
            </template>
          </Column>
          <Column field="jumlah" header="Total" style="min-width: 15rem">
            <template #body="{ data }">
              {{ formatCurrency(data.jumlah) }}
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
    <div class="flex justify-content-end align-items-end">
      <Button
        label="Tutup"
        icon="pi pi-times"
        class="p-button-text mr-2"
        @click="$emit('close')"
      />
      <Button
        :disabled="disabledSave"
        label="Tambahkan"
        icon="pi pi-check"
        @click="add"
      />
    </div>
  </div>
</template>

<script>
import { formatCurrency, formatDate } from '@/helpers'
import { FilterMatchMode, FilterOperator } from 'primevue/api'

export default {
  props: {
    invoice: {
      type: Array,
      default() {
        return []
      },
    },
    excludeList: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      selected: null,
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        si_no: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        si_at: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        jumlah: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
      },
      disabledSave: true,
    }
  },
  watch: {
    selected(val) {
      if (val && val.length > 0) {
        this.disabledSave = false
      } else {
        this.disabledSave = true
      }
    },
  },
  computed: {
    list_invoice() {
      const result = this.invoice.slice()
      // Tampilkan data invoice hanya yang belum dipilih
      return result.filter((item) => {
        return this.excludeList.indexOf(item.id) === -1
      })
    },
  },
  methods: {
    add() {
      this.$emit('save', this.selected)
    },
    formatCurrency(value, minDigit = 0, maxDigit = 0) {
      value = value ? value : 0
      return formatCurrency(value, minDigit, maxDigit)
    },
    formatDate(value) {
      return formatDate(value)
    },
  },
}
</script>
