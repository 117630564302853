<template>
  <div>
    <DataTable
      :value="items"
      :loading="loading"
      class="p-datatable-sm"
      dataKey="kb_no"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      currentPageReportTemplate="Memuat {first} dari {last} data dari total {totalRecords}"
      row-hover
      scrollable
    >
      <template #empty>
        <div class="text-center">Tidak ada data.</div>
      </template>
      <template #loading>
        <div class="text-center">Memuat data, harap tunggu...</div>
      </template>
      <Column field="kb_no" header="No Kontra" style="min-width: 9rem" sortable>
        <template #body="{ data }">
          {{ !data.kb_no ? '-' : data.kb_no }}
        </template>
      </Column>
      <Column
        field="kb_no"
        header="No Invoice"
        style="min-width: 9rem"
        sortable
      >
        <template #body="{ data }">
          {{ data.si_no }}
        </template>
      </Column>
      <Column field="sr_no" header="No Retur" style="min-width: 9rem" sortable>
        <template #body="{ data }">
          {{ data.sr_no }}
        </template>
      </Column>
      <Column
        dataType="date"
        field="item_at"
        header="Tgl Bukti"
        style="min-width: 4rem"
        sortable
      >
        <template #body="{ data }">
          {{ formatDate(data.item_at) }}
        </template>
      </Column>
      <!--
      <Column
        field="type"
        header="Type"
        style="flex-grow: 1; justify-content: flex-end"
      >
        <template #body="{ data }">
          {{ data.type }}
        </template>
      </Column>
      -->
      <Column
        field="jumlah"
        header="Sisa"
        style="min-width: 100px; flex-grow: 1; justify-content: flex-end"
      >
        <template #body="{ data }">
          {{ formatCurrency(data.jumlah) }}
        </template>
      </Column>
      <!--<Column
        field="diskon"
        header="Diskon"
        style="min-width: 100px; flex-grow: 1; justify-content: flex-end"
      >
        <template #body="{ data }">
          {{ formatCurrency(data.diskon) }}
        </template>
      </Column>-->
      <Column
        field="jumlah_dibayar"
        header="Jumlah"
        style="min-width: 100px; flex-grow: 1; justify-content: flex-end"
      >
        <template #body="{ data }">
          {{ formatCurrency(data.jumlah_dibayar) }}
        </template>
      </Column>
      <Column field="keterangan" header="Keterangan" style="min-width: 150px" />
      <Column
        v-if="showButton"
        style="flex-grow: 1; min-width: 100px; justify-content: flex-end"
      >
        <template #body="{ data }">
          <Button v-if="!data.sr_no"
            type="button"
            icon="pi pi-pencil"
            class="p-button-outlined p-button-secondary p-button-text mr-2"
            @click="$emit('edit', data)"
          />
          <Button
            type="button"
            icon="pi pi-trash"
            class="p-button-outlined p-button-danger p-button-text"
            @click="$emit('delete', data)"
          />
        </template>
      </Column>
      <template #footer>
        <table width="340px" class="flex justify-content-end">
          <tr>
            <td width="100%">
              <table class="justify-content-end">
                <tr>
                  <td>JUMLAH LUNAS</td>
                  <td style="width: 150px" class="text-right">
                    {{ formatCurrency(subTotal) }}
                  </td>
                </tr>
                <tr>
                  <td>CN&nbsp;</td>
                  <td class="text-right">
                    <InputNumber
                      v-model="credit"
                      :minFractionDigits="0"
                      :min="0"
                      :disabled="!showButton"
                      mode="currency"
                      currency="IDR"
                      locale="id-ID"
                      @input="updateCN"
                    />
                  </td>
                </tr>
                <tr>
                  <td>DN&nbsp;</td>
                  <td class="text-right">
                    <InputNumber
                      v-model="debit"
                      :minFractionDigits="0"
                      :min="0"
                      :disabled="!showButton"
                      mode="currency"
                      currency="IDR"
                      locale="id-ID"
                      @input="updateDN"
                    />
                  </td>
                </tr>
                <!--
                <tr>
                  <td>MATERAI&nbsp;</td>
                  <td class="text-right">
                    <InputNumber
                      v-model="nilaiMaterai"
                      :minFractionDigits="0"
                      :min="0"
                      :disabled="!showButton"
                      mode="currency"
                      currency="IDR"
                      locale="id-ID"
                      @input="updateMaterai"
                    />
                  </td>
                </tr>
                -->
                <tr>
                  <td>JUMLAH DIBAYAR</td>
                  <td class="text-right">
                    {{ formatCurrency(jumlah_total) }}
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </template>
    </DataTable>
  </div>
</template>

<script>
import { formatCurrency, formatDate } from '@/helpers'

export default {
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showButton: {
      type: Boolean,
      default: false,
    },
    cn: {
      type: Number,
      default: 0,
    },
    dn: {
      type: Number,
      default: 0,
    },
    materai: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      debit: this.dn,
      credit: this.cn,
      //nilaiMaterai: this.materai,
      nilaiMaterai: 0,
      jumlah_total: 0,
    }
  },
  computed: {
    subTotal() {
      return this.items.reduce(function (total, item) {
        let type = item.type ? item.type : 0
        let jumlah = item.jumlah // - item.diskon
        if (type === 3) {
          jumlah = jumlah * -1
        }
        return total + jumlah
      }, 0)
    },
    subTotalBayar() {
      return this.items.reduce(function (total, item) {
        let type = item.type ? item.type : 0
        let jumlah = item.jumlah_dibayar
        if (type === 3) {
          //jumlah = (jumlah - item.diskon) * -1
          jumlah = jumlah * -1
        }
        return total + jumlah
      }, 0)
    },
  },
  watch: {
    dn(e) {
      this.debit = e
      this.jumlah_total = this.subTotalBayar - this.cn + e //+ this.materai
    },
    cn(e) {
      this.credit = e
      this.jumlah_total = this.subTotalBayar - e + this.dn //+ this.materai
    },
    materai() {
      this.nilaiMaterai = 0 //e
      this.jumlah_total = this.subTotalBayar - this.cn + this.dn //+ this.nilaiMaterai
    },
    /*
    subTotal(e) {
      this.jumlah_total = e
    },
    */
    subTotalBayar(e) {
      this.jumlah_total = e - this.cn + this.dn //+ this.materai
    },
  },
  methods: {
    updateDN(e) {
      const totalJumlah = this.subTotalBayar
      //const materai = this.nilaiMaterai === null ? 0 : this.nilaiMaterai
      //const cn = this.cn === null ? 0 : this.cn
      const dn = e.value === null ? 0 : e.value
      this.credit = 0;
      this.jumlah_total = totalJumlah + dn //+ materai
      this.$emit('dn', dn)
      this.$emit('cn', this.credit)
    },
    updateCN(e) {
      const totalJumlah = this.subTotalBayar
      //const materai = this.nilaiMaterai === null ? 0 : this.nilaiMaterai
      //const dn = this.dn === null ? 0 : this.dn
      const cn = e.value === null ? 0 : e.value
      this.debit = 0
      this.jumlah_total = totalJumlah - cn //+ materai
      this.$emit('cn', cn)
      this.$emit('dn', this.debit)
    },
    updateMaterai() {
      const totalJumlah = this.subTotalBayar
      //const materai = e.value === null ? 0 : e.value
      const dn = this.dn === null ? 0 : this.dn
      const cn = this.cn === null ? 0 : this.cn
      this.jumlah_total = totalJumlah - cn + dn //+ materai
      //this.$emit('materai', materai)
    },
    formatCurrency(value) {
      return formatCurrency(value)
    },
    formatDate(value) {
      return formatDate(value)
    },
  },
}
</script>
